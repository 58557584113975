<template>

<div v-if="loading">
  <div class="m-4">
            <progress class="progress is-small is-light" max="30">15%</progress> 
        </div>
</div>
  <div  v-else>
    <div class="title">العملاء</div>
            <a class="button is-danger is-light ml-2"
          :class="{ 'is-active': visibility === 'New' }"
          @click="visibility = 'New'"
          >New</a
        >
      
        <a class="button is-success is-light ml-2"
          :class="{ 'is-active': visibility === 'Approved' }"
          @click="visibility = 'Approved'"
          >Approved</a
        >
     
                <a class="button is-dark is-light ml-2"
          :class="{ 'is-active': visibility === 'all' }"
          @click="visibility = 'all'"
          >All</a
        >
    
    
      
        <div  v-if="users.length" class="  " >
    <table class="table is-striped is-fullwidth">
  <thead>
    <tr>
      <th><abbr title="Position">ID</abbr></th>
      <th>الحالة</th>
      <th>user ID</th>
      <th>الهاتف </th>
      <th>الرصيد </th>
      <th>العمولة </th>
      <!-- <th>المدفوعات</th> -->
          <!-- <th>bank</th> -->
      <!-- <th>بطاقة وطنية </th> -->
  
       <!-- <th>fullname </th> -->
      
      <th></th>




    </tr>
  </thead>

  <tbody>
    <tr v-for="user in filteredUsers" :key="user.id">
      <th>{{user.id}}</th>
      <td><span v-if="user.is_approved"><b class="tag is-success">Active</b></span><span class="tag is-danger" v-else>Not Active</span></td>


      <td>{{user.username}}</td>
      <td>{{user.telephone}}</td>
      <!-- <td>{{user.balance}}  </td>
      <td>{{user.fees}}  </td> -->
      <!-- <td>{{user.total_paid}}  </td> -->
      <td>{{user.store_name}}  </td>


  
      <td>{{user.orders_count }} </td>
      <!-- <td ><b v-if="user.balance_net > 0" class="tag is-primary">{{user.balance_net }} MAD</b> <b v-else-if="user.balance_net < 0" class="tag is-danger">{{user.balance_net }} MAD</b></td> -->

    <!-- <td> <router-link   :to="{ name: 'Payuser', params: { id: user.id }}"><b class="button is-dark">View</b></router-link>  </td> -->


    </tr>
  </tbody>
            </table>
            


        </div>
        <p v-else > You have no users</p>


  </div>
  <hr>
  
</template>

<script>
var filters = {
  all: function (users) {
    return users;
  },
  New: function (users) {
    return users.filter(function (user) {
      return !user.is_approved;
    });
  },
  Approved: function (users) {
    return users.filter(function (user) {
      return user.is_approved;
    });
  },
  
};

import axios from 'axios';
import _ from 'lodash';
name: 'Adminusers'
export default {
    data(){
        return{
          visibility: 'Approved',
          loading: true,
            users: []
        }
    },
    mounted(){
        this.getAdminUsers()
    },
    methods:{
        getAdminUsers(){
            axios
            .get('/api/v1/users-info-admin/')
            .then(response =>{
                this.users = response.data
                console.log('response.data:', response.data);
                this.loading = false
            })
            .catch(error =>{
               
                  toast({
                    message: `${error}`,
                    type : 'is-danger',
                    dismissible: true,
                    duration: 5000,
                    position:'bottom-right',
                    })
            })
        }
    },

  computed: {
    filteredUsers() {
      const all =  filters[this.visibility](this.users);
      return _.orderBy(all, 'id')
    },


  },

}
</script>

<style>

</style>